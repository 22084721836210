// --------------------------------------- Project List
export const ProjectList = [
  {
    img: "/2048.png",
    title: "2048 - Number Merge",
    description:
      "The classic 2048 game with a twist. Merge numbers to get the highest score.",
    tech_stack: ["Flutter", "Riverpod", "Firebase Analytics", "Admob Ads"],
    github_url: "",
    demo_url:
      "https://play.google.com/store/apps/details?id=tech.shovo.numbercrunch&fbclid=IwAR0u_iUgt1PBji9GLuNVvLPEZa0ceCNkxFZn9Ya9EWSD1wwNqcSdub_9Dnw",
  },
  {
    img: "/tetris.png",
    title: "MASTER BRICK 2D - BRICK GAME",
    description:
      "A Tetris game where users can play and compete with each other.",
    tech_stack: ["Flutter", "Provider", "Firebase Analytics", "Admob Ads"],
    github_url: "",
    demo_url:
      "https://play.google.com/store/apps/details?id=com.violettechapp.tetris",
  },
  {
    img: "/vscanner.png",
    title: "VSCANNER: QR & BARCODE SCANNER",
    description:
      "A QR and Barcode scanner app that can scan and generate QR codes.",
    tech_stack: ["Flutter", "BloC", "Firebase Analytics", "Admob Ads"],
    github_url: "",
    demo_url:
      "https://play.google.com/store/apps/details?id=com.violettechapp.vscanner",
  },
  {
    img: "/mpt.png",
    title: "MODERN PERIODIC TABLE - MPT",
    description:
      "A modern periodic table app with detailed information about elements.",
    tech_stack: ["Flutter", "BloC", "Firebase Analytics", "Admob Ads"],
    github_url: "",
    demo_url:
      "https://play.google.com/store/apps/details?id=com.violettechapp.modern_periodic_table",
  },
];

// --------------------------------------- Skills

export const stackList = [
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/flutter/flutter-original.svg",
    name: "Flutter",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/arduino/arduino-original.svg",
    name: "Arduino",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/dart/dart-original.svg",
    name: "Dart",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
    name: "ReactJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original.svg",
    name: "Docker",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg",
    name: "NodeJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/git/git-original.svg",
    name: "Git",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/github/github-original.svg",
    name: "Github",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/django/django-plain.svg",
    name: "Django",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/firebase/firebase-plain.svg",
    name: "Firebase",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original.svg",
    name: "MongoDB",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
    name: "Python",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/matlab/matlab-original.svg",
    name: "Matlab",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/c/c-original.svg",
    name: "C",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/postman/postman-original.svg",
    name: "Postman",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original.svg",
    name: "MySQL",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/linux/linux-original.svg",
    name: "Linux",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/debian/debian-original.svg",
    name: "Debian",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/figma/figma-original.svg",
    name: "Figma",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/opencv/opencv-original.svg",
    name: "OpenCV",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/vscode/vscode-original.svg",
    name: "VS Code",
  },
];
